<template>
  <div class="tw-flex tw-justify-start tw-gap-4 tw-px-4 sm:tw-px-0">
    <div
      class="
        summary-icon
        tw-flex tw-justify-center tw-items-center tw-rounded-full
        c-bg-blue
        tw-h-12
      "
    >
      <img
        class="tw-w-5 tw-h-5 tw-p-0.5"
        src="@/assets/img/summaryicon.png"
        alt="icon"
      />
    </div>

    <div class="tw-flex tw-flex-col tw-gap-1">
      <v-skeleton-loader
        v-if="loading"
        type="text"
        height="20px"
        width="60px"
        class="!tw-m-0"
      >
      </v-skeleton-loader>
      <p class="mb-0 tw-text-2xl" v-else>{{ count }}</p>
      <p class="mb-0 c-gray-text">{{ title }}</p>
      <router-link :to="`/dashboard/report/?category=${category}`"
        >See all</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: Number,
    title: String,
    loading: Boolean,
    category: String,
  },
}
</script>

<style>
.summary-icon {
  min-width: 48px;
  width: 48px;
}
</style>
